<template>
  <ChartBox
    id="cumulativeUsersContainer"
    title="Kümülatif üye sayısı"
    subtitle="İlgili tarihe kadar kayıt oluşturmuş toplam üye sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isCumulativeUsersEmpty"
      :loading="loading"
      :isEmpty="isCumulativeUsersEmpty"
    />
    <ChartColumn
      v-else
      chartId="cumulativeUsers"
      :data="generateColumnChartData(cumulativeUsers, 'Üye')"
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const cumulativeUsers = computed(() => statusStore.cumulativeUsers);
const isCumulativeUsersEmpty = computed(
  () => !cumulativeUsers.value || cumulativeUsers.value.length === 0
);

const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadCumulativeUsers(
      filter.value.sponsorIdentifier,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorIdentifier, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
