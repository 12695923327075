<template>
  <ChartBox
    id="popularContentsContainer"
    title="Popüler içerik konuları"
    subtitle="En çok ilgi çeken içerik konuları."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isEmpty"
      :isEmpty="isEmpty"
      :loading="loading"
    />
    <div v-else class="grid gap-1">
      <ReportsChartsContentPopularRankingChange
        v-for="item in contentPopularity"
        :key="item.key"
        :popularity="item"
      />
    </div>
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const contentPopularity = computed(() => statusStore.contentPopularity);
const isEmpty = computed(
  () => !contentPopularity.value || contentPopularity.value.length === 0
);
const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadContentPopularity(
      filter.value.sponsorIdentifier,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorIdentifier, filter.value.range],
  async ([newSponsorId, newRange], [oldSponsorId, oldRange]) => {
    if (newSponsorId !== oldSponsorId || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
