<template>
  <ChartBox
    title="Seans yapan kişi sayısı"
    subtitle="İlgili ay içerisinde seans yapan toplam çalışan sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isUserWithMeetingEmpty"
      :isEmpty="isUserWithMeetingEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      chartId="usersWithMeeting"
      :data="generateColumnChartData(usersWithMeeting, 'Üye')"
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const usersWithMeeting = computed(() => statusStore.usersWithMeeting);
const isUserWithMeetingEmpty = computed(
  () => !usersWithMeeting.value || usersWithMeeting.value.length === 0
);
const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadUsersWithMeeting(
      filter.value.sponsorIdentifier,
      filter.value.range,
      filter.value.vertical
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [
    filter.value.sponsorIdentifier,
    filter.value.range,
    filter.value.vertical,
  ],
  async (
    [newSponsorId, newRange, newVertical],
    [oldSponsorId, oldRange, oldVertical]
  ) => {
    if (
      newSponsorId !== oldSponsorId ||
      newRange !== oldRange ||
      newVertical !== oldVertical
    ) {
      await fetchData();
    }
  }
);
</script>
