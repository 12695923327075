<template>
  <ModalSlideSheet
    title="Şirketi değiştirin"
    :open="open"
    :onClose="onClose"
    :onBack="onClose"
  >
    <template #content>
      <AppBaseColumn gap="small" class="w-full">
        <span class="l1 text-start">Şirket seçin</span>
        <OptionItemSingle
          v-for="sponsor in availableSponsors"
          :key="sponsor.identifier"
          :option="sponsor.name"
          :isSelected="sponsor.identifier === selectedIdentifier"
          :onSelect="() => onSelect(sponsor.identifier)"
        />
      </AppBaseColumn>
    </template>
    <template #footer-secondary-button>
      <ButtonSecondaryMedium label="Vazgeçin" @click="() => onClose()" />
    </template>
    <template #footer-primary-button>
      <ButtonPrimaryMedium
        label="Güncelleyin"
        :disabled="selectedIdentifier === initialIdentifier"
        @click="onConfirm"
      />
    </template>
  </ModalSlideSheet>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const userStore = useUserStore();
const statusStore = useStatusStore();

const availableSponsors = computed(() => userStore.insightsProfile.sponsors);
const initialIdentifier = computed(() => statusStore.filter.sponsorIdentifier);

const selectedIdentifier = ref(initialIdentifier.value);
const onSelect = (id: string) => {
  selectedIdentifier.value = id;
};

const onConfirm = () => {
  statusStore.updateFilterSponsorIdentifier(selectedIdentifier.value);
  props.onClose();
};
</script>
