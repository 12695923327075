<template>
  <ChartBox
    title="Seans sayısı"
    subtitle="İlgili ay içerisinde yapılan toplam seans sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isVerticalMeetingsEmpty"
      :isEmpty="isVerticalMeetingsEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      chartId="verticalMeetings"
      :data="generateColumnChartData(verticalMeetings, 'Seans')"
      yAxisTitle="Seans sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const verticalMeetings = computed(() => statusStore.meetings);
const isVerticalMeetingsEmpty = computed(
  () => !verticalMeetings.value || verticalMeetings.value.length === 0
);

const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadMeetings(
      filter.value.sponsorIdentifier,
      filter.value.range,
      filter.value.vertical
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [
    filter.value.sponsorIdentifier,
    filter.value.range,
    filter.value.vertical,
  ],
  async (
    [newSponsorId, newRange, newVertical],
    [oldSponsorId, oldRange, oldVertical]
  ) => {
    if (
      newSponsorId !== oldSponsorId ||
      newRange !== oldRange ||
      newVertical !== oldVertical
    ) {
      await fetchData();
    }
  }
);
</script>
