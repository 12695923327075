<template>
  <ChartBox
    title="Kişi başı seans dağılımı"
    subtitle="Çalışanların yaptıkları toplam görüşme sayılarının dağılımı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isMeetingCountsPerUserEmpty"
      :isEmpty="isMeetingCountsPerUserEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      chartId="verticalMeetingsPerUser"
      :data="generateColumnChartDataFromKeyValue(meetingCountsPerUser, 'Kişi')"
      yAxisTitle="Kişi sayısı"
      :showSimpleTooltip="true"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const meetingCountsPerUser = computed(() => statusStore.meetingCountsPerUser);
const isMeetingCountsPerUserEmpty = computed(
  () => !meetingCountsPerUser.value || meetingCountsPerUser.value.length === 0
);

const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadMeetingCountsPerUser(
      filter.value.sponsorIdentifier,
      filter.value.range,
      filter.value.vertical
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [
    filter.value.sponsorIdentifier,
    filter.value.range,
    filter.value.vertical,
  ],
  async (
    [newSponsorId, newRange, newVertical],
    [oldSponsorId, oldRange, oldVertical]
  ) => {
    if (
      newSponsorId !== oldSponsorId ||
      newRange !== oldRange ||
      newVertical !== oldVertical
    ) {
      await fetchData();
    }
  }
);
</script>
