<template>
  <AppChip
    :label="label"
    :textColor="selected ? 'text-surface-10' : 'text-primary-60'"
    :backgroundColor="selected ? 'bg-primary-60' : 'bg-transparent'"
    :borderColor="selected ? 'border-primary-60' : 'border-primary-60'"
    class="cursor-pointer"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  },
  onClick: {
    type: Function,
    required: true,
  },
});

const handleClick = () => {
  props.onClick();
};
</script>
