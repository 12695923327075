<template>
  <ModalExportConfirm
    :open="exportDialogOpen"
    :onClose="() => (exportDialogOpen = false)"
    :onConfirm="onPdfExportConfirm"
  />
  <SalusLoading
    v-if="isPdfExporting"
    :isLoading="isPdfExporting"
    loadingText="Raporu oluşturmaya başladık. Bu işlem birkaç dakika sürebilir."
  />
  <SalusLoading v-if="loading" :isLoading="loading" />
  <LayoutWebexpSingleColumn v-else id="overview" :fullWidth="true">
    <AppBaseColumn gap="xlarge" color="bg-surface-base">
      <ReportsHeader />
      <AppBaseRow color="bg-surface-base">
        <ReportsFilterRange />
        <ButtonPrimaryLarge
          label="PDF olarak indirin"
          @click="onPdfExportClick()"
        />
      </AppBaseRow>
      <AppBaseColumn gap="large" color="bg-surface-base">
        <span class="h2 m lg:d">Özet</span>
        <ReportsChartsUserSummary />
        <div class="grid gap-6 grid-cols-2 h-[365px]">
          <ReportsChartsCumulativeUsers />
          <ReportsChartsCumulativeMeetings />
        </div>
        <div class="grid gap-6 h-[400px]">
          <ReportsChartsNewcomingUsers />
        </div>
        <div class="grid gap-6 h-[400px]">
          <ReportsChartsActiveUsers />
        </div>
      </AppBaseColumn>
      <AppBaseColumn gap="large" color="bg-surface-base">
        <span class="h2 m lg:d">Seanslar</span>
        <ReportsFilterVertical />
        <div class="grid gap-6 grid-cols-2 h-[365px]">
          <ReportsChartsVerticalMeetings />
          <ReportsChartsVerticalUsers />
        </div>
        <div class="grid gap-6 h-[400px]">
          <ReportsChartsVerticalMeetingsPerUser />
        </div>
      </AppBaseColumn>
      <AppBaseColumn v-if="isPdfExporting" gap="large" color="bg-surface-base">
        <div class="grid gap-6 grid-cols-2">
          <ReportsChartsVerticalExportMeetings
            v-for="vertical in verticalOptions"
            :key="vertical"
            :id="vertical + 'Meetings'"
            :defaultVertical="vertical"
            class="h-[365px]"
          />
          <ReportsChartsVerticalExportUsers
            v-for="vertical in verticalOptions"
            :key="vertical"
            :id="vertical + 'Users'"
            :defaultVertical="vertical"
            class="h-[365px]"
          />
        </div>
        <div class="grid gap-6">
          <ReportsChartsVerticalExportMeetingsPerUser
            v-for="vertical in verticalOptions"
            :key="vertical"
            :id="vertical + 'MeetingsPerUser'"
            :defaultVertical="vertical"
            class="h-[400px]"
          />
        </div>
      </AppBaseColumn>
      <AppBaseColumn gap="large" color="bg-surface-base">
        <span class="h2 m lg:d">Pozitif etki</span>
        <AppBaseColumn gap="small" color="bg-surface-base">
          <div class="grid gap-6 h-[420px]">
            <ReportsChartsCheckups />
          </div>
          <div class="flex items-center justify-center">
            <ButtonSecondaryMedium
              label="Testler hakkında bilgi almak için tıklayın"
              @click="onTestInfoClick"
            />
          </div>
        </AppBaseColumn>
        <div class="grid gap-6 grid-cols-3 h-[400px]">
          <ReportsChartsAverageMeetingDuration />
          <ReportsChartsAverageContentConsumption />
        </div>
      </AppBaseColumn>
      <AppBaseColumn gap="large" color="bg-surface-base">
        <span class="h2 m lg:d">İçerikler</span>
        <div class="grid gap-6 grid-cols-2 h-[365px]">
          <ReportsChartsContentPopular />
          <ReportsChartsContentByType />
        </div>
      </AppBaseColumn>
      <AppBaseColumn gap="large" color="bg-surface-base">
        <span class="h2 m lg:d">Demografi</span>
        <div class="grid gap-6 grid-cols-3 h-[365px]">
          <ReportsChartsDemography />
        </div>
      </AppBaseColumn>
    </AppBaseColumn>
  </LayoutWebexpSingleColumn>
</template>
<script setup lang="ts">
import { useStatusStore, verticalOptions } from "~/store/status";
import { useUserStore } from "~/store/user";
definePageMeta({
  title: "Genel Bakış",
  requiresAuth: true,
});

const userStore = useUserStore();
const statusStore = useStatusStore();
userStore.loadInsightsProfile();
const insightsProfile = computed(() => userStore.insightsProfile);
const filterSponsorIdentifier = computed(
  () => statusStore.filter.sponsorIdentifier
);
const filterSponsor = computed(() =>
  userStore.getSponsorById(filterSponsorIdentifier.value)
);
const loading = computed(() => userStore.isLoading);
const isPdfExporting = computed(() => statusStore.isPdfExporting);

const exportDialogOpen = ref(false);
const onPdfExportClick = () => {
  exportDialogOpen.value = true;
};

const onPdfExportConfirm = async () => {
  exportDialogOpen.value = false;
  try {
    await statusStore.exportToPdf(
      filterSponsor.value.logoUrl,
      filterSponsor.value.name
    );
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  }
};

const onTestInfoClick = () => {
  window.open(
    "https://www.getheltia.com/blog/heltianin-bilimsel-yaklasimi",
    "_blank"
  );
};

watch(insightsProfile, (newProfile) => {
  if (newProfile) {
    statusStore.initializeFilter(newProfile);
  }
});
</script>
