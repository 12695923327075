<template>
  <div class="flex gap-2 items-center">
    <div
      class="w-8 h-8 rounded-full bg-primary-10 flex items-center justify-center"
    >
      <span class="l2 text-primary-60"> {{ popularity.newOrder }}</span>
    </div>
    <span class="b2">{{ popularity.key }}</span>
    <!-- <span :class="[textColor, iconName, 'text-base']" /> -->
    <!-- <span :class="['l3', textColor]">
      {{ orderText }}
    </span> -->
  </div>
</template>
<script setup lang="ts">
import type { Popularity } from "~/model/status";

const props = defineProps({
  popularity: {
    type: Object as PropType<Popularity>,
    required: true,
  },
});

const { popularity } = props;

const orderChangeVisible = computed(() => popularity.oldOrder !== 0);

const textColor = computed(() => {
  if (popularity.newOrder === popularity.oldOrder) {
    return "text-system-warning-base";
  }

  return popularity.newOrder > popularity.oldOrder
    ? "text-system-success-base"
    : "text-system-danger-base";
});

const iconName = computed(() => {
  if (popularity.newOrder === popularity.oldOrder) {
    return "icon-notchaged";
  }

  return popularity.newOrder > popularity.oldOrder
    ? "icon-increase"
    : "icon-decrease";
});

const orderText = computed(() => {
  if (popularity.newOrder === popularity.oldOrder) {
    return "Sıralaması Değişmedi";
  }

  return popularity.newOrder > popularity.oldOrder
    ? popularity.newOrder + " sıra yükseldi"
    : popularity.newOrder + " sıra düştü";
});
</script>
