import { format } from "date-fns";
import { tr } from "date-fns/locale";
import type { Aggregation } from "~/model/status";

export const generateColumnChartData = (data: Aggregation[], name: string) => {
  if (!data || data.length === 0) {
    return [];
  }

  const chartData = data.map((item) => ({
    series: [{ name: name, value: item.value }],
    category: getMonthName(item.month),
  }));

  return chartData;
};

export const generateMultiColumnChartData = (
  firstData: Aggregation[],
  firstName: string,
  secondData: Aggregation[],
  secondName: string
) => {
  if (
    (!firstData || firstData.length === 0) &&
    (!secondData || secondData.length === 0)
  ) {
    return [];
  }

  const chartData = firstData.map((item, index) => ({
    series: [
      { name: firstName, value: item.value },
      { name: secondName, value: secondData[index]?.value || 0 },
    ],
    category: getMonthName(item.month),
  }));

  return chartData;
};

export const generateColumnChartDataFromKeyValue = (
  data: { [key: string]: number },
  name: string
) => {
  if (!data) {
    return [];
  }

  const chartData = Object.keys(data).map((key) => ({
    series: [{ name: name, value: data[key] }],
    category: key,
  }));

  return chartData;
};

export const generateDonutChartData = (
  data: { [key: string]: number },
  name: string,
  displayNameDictionary?: { [key: string]: string }
) => {
  if (!data) {
    return null;
  }

  const series = Object.entries(data).map(([key, value]) => {
    const displayName = displayNameDictionary
      ? displayNameDictionary[key]
      : key;
    return {
      name: displayName,
      value: value,
    };
  });

  return {
    category: name,
    series: series,
  };
};

function getMonthName(month: number) {
  const year = new Date().getFullYear();
  const date = new Date(year, month - 1, 10);
  return format(date, "LLLL", { locale: tr });
}
