<template>
  <Confirmation
    :open="open"
    :text="`${rangeText} verilerini PDF olarak indirmek üzeresiniz.`"
    confirmActionText="PDF olarak indirin"
    closeActionText="Vazgeçin"
    :onConfirm="onConfirm"
    :onCancel="onClose"
    :isPopUp="true"
  />
</template>
<script setup lang="ts">
import { useStatusStore, type FilterRange } from "~/store/status";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
});

const statusStore = useStatusStore();
const rangeFilter = computed(() => statusStore.filter.range);

const rangeText = computed(() => {
  const dict: Record<FilterRange, string> = {
    last3Months: "Son 3 ayın",
    last6Months: "Son 6 ayın",
    lastYear: "Son 1 yılın",
  };

  return dict[rangeFilter.value];
});
</script>
