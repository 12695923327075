<template>
  <ChartBase
    :chartId="chartId"
    :chartOptions="columnOptions"
    :initialWidth="400"
    :initialHeight="220"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";

export type ColumnChartData = {
  category: string;
  series: {
    name: string;
    value: number;
  }[];
};

const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  data: {
    type: Array as PropType<ColumnChartData[]>,
    required: true,
  },
  yAxisTitle: {
    type: String,
    required: false,
  },
  showSimpleTooltip: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { data, yAxisTitle, chartId, showSimpleTooltip } = props;

const nuxtApp = useNuxtApp();

const highchartStyles = nuxtApp.$highchartStyles;
const { fonts, colors } = highchartStyles ?? {};

const globalColors = nuxtApp.$highcharts?.getOptions()?.colors;

const categories = data.map((item) => item.category);
const seriesNames =
  data.length > 0 ? data[0].series.map((item) => item.name) : [];
const seriesData = seriesNames.map((name, index) => ({
  name,
  data: data.map((item) => {
    const seriesItem = item.series.find((s) => s.name === name);
    return seriesItem ? seriesItem.value : 0;
  }),
  color: globalColors?.[index],
}));

const columnOptions = ref({
  chart: {
    type: "column",
    backgroundColor: colors?.surface[10],
  },
  title: {
    text: null,
  },
  xAxis: {
    categories: categories,
    labels: {
      style: seriesNames.length > 1 ? fonts?.l2 : fonts?.l3,
    },
  },
  yAxis: {
    min: 0,
    labels: {
      style: fonts?.l3,
    },
    title: {
      text: yAxisTitle,
      style: fonts?.l3,
    },
    tickAmount: 5,
  },
  legend: {
    enabled: seriesNames.length > 1,
    itemStyle: fonts?.l3,
  },
  plotOptions: {
    column: {
      borderRadius: 4,
      pointPadding: 0.1,
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: "none",
        formatter(this: Highcharts.Point) {
          return new Intl.NumberFormat("tr-TR").format(this.y);
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    style: {
      pointerEvents: "auto",
      zIndex: 10000,
    },
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      const currentPoint = this.point;
      const previousPoint = this.series.data[this.point.index - 1];
      let comparisonText = "";
      let iconComponent = "";

      if (
        previousPoint &&
        previousPoint.y !== 0 &&
        currentPoint.y !== 0 &&
        previousPoint.y !== null
      ) {
        const percentageChange =
          ((currentPoint.y - previousPoint.y) / previousPoint.y) * 100;

        let changeType = "nochange";

        if (percentageChange > 0) {
          changeType = "increase";
        } else if (percentageChange < 0) {
          changeType = "decrease";
        }

        const colors = {
          increase: "text-system-success-base",
          decrease: "text-system-alert-base",
          nochange: "text-system-warning-60",
        };

        const icons = {
          increase: "icon-increase",
          decrease: "icon-decrease",
          nochange: "icon-notchaged",
        };

        const comparisonTexts = {
          increase: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} artış`,
          decrease: `%${parseFloat(
            Math.abs(percentageChange).toFixed(2)
          )} düşüş`,
          nochange: "Değişim yok",
        };

        comparisonText = `<span class="${colors[changeType]}">${comparisonTexts[changeType]}</span>`;

        iconComponent = `<span class="w-[14px] h-[14px] ${colors[changeType]} ${icons[changeType]} "></span>`;
      }

      return `
        <div class="grid gap-3 b3 bg-surface-10">
          <div class="grid">
            <span class="text-neutral-40">${currentPoint.category}</span>
            <div class="flex items-center gap-1">
            <span style="background-color: ${
              this.series.color
            };" class="inline-block w-[6px] h-[6px] rounded-full "></span>
            <span>${currentPoint.series.name}: ${currentPoint.y} 
            </span>
            </div>
          </div>
          ${
            comparisonText && !showSimpleTooltip
              ? `
                <div class="grid">
                  <span class="text-neutral-40">Geçen aya göre</span>
                  <div class="flex gap-1 items-center">
                    ${iconComponent}
                    ${comparisonText}
                  </div>
                </div>
                `
              : ""
          }
        </div>
      `;
    },
  },
  series: seriesData,
});
</script>
