<template>
  <ChartBase
    :chartId="chartId"
    :chartOptions="gaugeOptions"
    :initialWidth="200"
    :initialHeight="160"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  chartId: {
    type: String,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  valueKind: {
    type: String,
    required: true,
  },
});

const { value, total, label, valueKind, chartId } = props;

const percentage = (value / total) * 100;

const nuxtApp = useNuxtApp();
const globalColors = nuxtApp.$highcharts?.getOptions()?.colors;

const highchartStyles = nuxtApp.$highchartStyles;
const { colors } = highchartStyles ?? {};

const gaugeOptions = ref({
  chart: {
    type: "solidgauge",
    backgroundColor: colors?.surface[10],
  },
  title: {
    text: "",
  },
  tooltip: {
    enabled: false,
  },
  pane: {
    center: ["50%", "50%"],
    size: 160,
    startAngle: 0,
    endAngle: 360,
    background: {
      backgroundColor: globalColors?.[globalColors.length - 1],
      innerRadius: "70%",
      outerRadius: "100%",
      shape: "arc",
      borderWidth: 0,
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: [],
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        verticalAlign: "middle",
        align: "center",
        borderWidth: 0,
        y: 0,
        useHTML: true,
        format: `
        <div class="grid justify-items-center">
          ${
            percentage === 100
              ? `<span class="s2 m lg:d">${value}</span><span class="s2 m lg:d">${valueKind}</span>`
              : `<span class="s2 m lg:d">%${parseFloat(
                  percentage.toFixed(1)
                )}</span>
                 <span class="b3 text-neutral-40">${value} ${valueKind}</span>`
          }
        </div>
      `,
      },
      rounded: true,
    },
  },
  series: [
    {
      name: label,
      data: [
        {
          radius: "100%",
          innerRadius: "70%",
          y: percentage,
        },
      ],
    },
  ],
});
</script>
